import React from 'react'
import { BrowserRouter, Route } from 'react-router-dom'
import Presentation from 'views/Presentation'

function App() {
  return (
    <BrowserRouter>
        <Route 
            path="/"
            render={(props) => <Presentation {...props} />}
        />
    </BrowserRouter>
  )
}

export default App