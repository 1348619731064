import React, { useEffect, useState } from 'react'
import Axios from 'axios'
import { Form, Col, Row, Input, FormGroup, CardBody, Button } from 'reactstrap'

const INITIAL_STATE = {
  nombre:'',
  apellido:'',
  email:'',
  telefono:'',
  mensaje:''
}

function Formulario(){
  const [data, setData] = useState(INITIAL_STATE)
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    console.log(data)
  },[data])

  const handleOnChange = event => {
    event.preventDefault()
    setData({...data, [event.target.name]: event.target.value})
  }
  const handleOnSubmit = (event) => {
    event.preventDefault();
		const { nombre, apellido, email, telefono, mensaje } = data 
		const message = `
			Nombre: ${nombre} ${apellido}
			Email: ${email} 
			Telefono: ${telefono}
			Mensaje: ${mensaje}
    `
    const info = {
      to:'altosdelquelhue@gmail.com',
      replyTo: email,
      subject:'Formulario Contacto',
      text: message
    }

		setIsLoading(true)
		Axios.post('https://us-central1-firemailer.cloudfunctions.net/submitContactoFZ',info)
			.then(res => {
				console.log(`mensaje enviado: ${res.data.isEmailSend}`)
				setIsLoading(false)
			})
			.catch(error => console.log(`ha ocurrido un error ${error}`))
		setData(INITIAL_STATE)
	};
  const { nombre, apellido, email, telefono, mensaje } = data
  const isDisabled = nombre === '' || apellido === '' || email === '' || telefono === ''
  return(
    <Form onSubmit={handleOnSubmit}>
        <CardBody>
            <Row>
                <Col md="6">
                    <FormGroup className="label-floating">
                        <label className="control-label">
                            Nombre
                        </label>
                        <Input
                            name="nombre"
                            value={nombre}
                            onChange={handleOnChange}
                            placeholder=""
                            type="text"
                        />
                    </FormGroup>
                </Col>
                <Col md="6">
                    <FormGroup className="label-floating">
                        <label className="control-label">
                            Apellido
                        </label>
                        <Input
                            name="apellido"
                            value={apellido}
                            onChange={handleOnChange}
                            placeholder=""
                            type="text"
                        />
                    </FormGroup>
                </Col>
            </Row>
            <Row>
                <Col>
                    <FormGroup className="label-floating">
                        <label className="control-label">
                            Teléfono
                        </label>
                        <Input
                            name="telefono"
                            value={telefono}
                            onChange={handleOnChange}
                            placeholder=""
                            type="text"
                        />
                    </FormGroup>
                </Col>
            </Row>
                <FormGroup className="label-floating">
                    <label className="control-label">
                        Email 
                    </label>
                    <Input
                            name="email"
                            value={email}
                            onChange={handleOnChange}
                            placeholder=""
                            type="email"
                        />
                    </FormGroup>
                    <FormGroup className="label-floating">
                        <label className="control-label">
                            Mensaje
                        </label>
                        <Input
                            id="message"
                            name="mensaje"
                            value={mensaje}
                            onChange={handleOnChange}
                            placeholder=""
                            type="textarea"
                            rows="6"
                        />
                    </FormGroup>
                    <Row>
                        <Col md="6">
                            <Button type ="submit" color="primary" disabled={isDisabled}>
                                {isLoading && 
                                    <div className="uil-reload-css reload-small mr-1">
                                        <div />
                                    </div>
                                }
                                Enviar
                            </Button>
                        </Col>
                    </Row>
                </CardBody>
    </Form>
  )}
export default Formulario
    // <Form onSubmit={handleOnSubmit}>
    //   <FormGroup>
    //     <Label>Nombre</Label>
    //     <Input
    //       type="text"
    //       name="nombre"
    //       value={nombre}
    //       onChange={handleOnChange}
    //     />
    //   </FormGroup>
    //   <FormGroup>
    //     <Label>
    //       Email
    //     </Label>
    //     <Input
    //       type="email"
    //       name="email"
    //       value={email}
    //       onChange={handleOnChange}
    //     />
    //   </FormGroup>
    //   <FormGroup>
    //     <Label>
    //       Telefono
    //     </Label>
    //     <Input
    //       type="text"
    //       name="telefono"
    //       value={telefono}
    //       onChange={handleOnChange}
    //     />
    //   </FormGroup>
    //   <FormGroup>
    //     <Label>
    //       Mensaje
    //     </Label>
    //     <Input
    //       type="text"
    //       name="mensaje"
    //       value={mensaje}
    //       onChange={handleOnChange}
    //     />
    //   </FormGroup>
    //   {isLoading ? 
    //     <span>Enviando...</span> :
    //     <Button
    //       type="submit"
    //       disabled={isDisabled}
    //     >
    //       Enviar
    //     </Button>
    //   }
    // </Form>

